<template>
  <div id="studio" v-show="ok">
    <div class="app-head">
      <span class="app-logo">{{ pageName }}</span>
      <span class="versionCss">1.1</span>
    </div>
    <div class="app-menu">
      <div class="app-menu-name">{{ pageTitle }}</div>
      <div class="app-menu-app" :style="appMenuIdx == 0 ? 'background: #1a2427;' : ''" @click="appMenuClick(0)">
        <div class="app-menu-area" v-show="appMenuIdx == 0"></div>
        已发布组态
      </div>
      <div class="app-menu-app" :style="appMenuIdx == 1 ? 'background: #1a2427;' : ''" @click="appMenuClick(1)">
        <div class="app-menu-area" v-show="appMenuIdx == 1"></div>
        待发布组态
      </div>
      <div class="app-menu-app" :style="appMenuIdx == 2 ? 'background: #1a2427;' : ''" @click="appMenuClick(2)">
        <div class="app-menu-area" v-show="appMenuIdx == 2"></div>
        我的素材库
      </div>
      <div class="app-menu-app" :style="appMenuIdx == 3 ? 'background: #1a2427;' : ''" @click="appMenuClick(3)">
        <div class="app-menu-area" v-show="appMenuIdx == 3"></div>
        公共素材库
      </div>
      <div class="app-menu-app" :style="appMenuIdx == 4 ? 'background: #1a2427;' : ''" @click="appMenuClick(4)">
        <div class="app-menu-area" v-show="appMenuIdx == 4"></div>
        音频素材库
      </div>
    </div>

    <mu-fade-transition>
      <div class="appComposeHeadCss" v-if="appMenuIdx == 0">
        <input type="text" placeholder="组态名称" class="layui-input" v-model="zutaiNameSearch" @keyup.enter="searchZutai" />
        <mu-button fab small color="primary" @click="searchZutai">
          <mu-icon size="22" value="search"></mu-icon>
        </mu-button>
        <div class="xsfsCss">
          <mu-button small :color="xsfsType === '列表' ? 'primary': ''" @click="xsfsSet('列表')">
            列表
          </mu-button>
          <mu-button small :color="xsfsType === '视图' ? 'primary': ''" @click="xsfsSet('视图')">
            视图
          </mu-button>
        </div>
      </div>
    </mu-fade-transition>
    <mu-fade-transition>
      <div class="appComposeCss" v-if="appMenuIdx == 0">
        <div v-if="xsfsType === '列表'">
          <mu-data-table stripe :columns="titleList" :data="showlist">
            <template slot-scope="{row}">
              <td class="is-center">
                <img class="tableImgCss" v-if="viewLock !== row.id && row.showImg" :src="
                  head + `://${appComposeImgUrl}/cover/` +
                  row.id +
                  '.png?rand=' +
                  parseInt(10 * Math.random())
                " />
              </td>
              <td class="is-center">{{row.name}}</td>
              <td class="is-center">{{row.id}}</td>
              <td>
                <a class="appComposeButCss" :style="row.release ? 'cursor:not-allowed;color:#c9c9c9' : ''"
                  @click="releaseEvent(row, true)">发布</a>
                <a class="appComposeButCss" @click="viewEvent(row.id)">运行</a>
                <a class="appComposeButCss" @click="editEvent(row.id)">编辑</a>
                <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(row, '映射')">映射</a>
                <a class="appComposeButCss" :style="row.release ? '' : 'cursor:not-allowed;color:#c9c9c9'"
                  @click="releaseEvent(row, false)">下架</a>
                <a class="appComposeButCss" @click="updateEvent(row)">设置</a>
                <a class="appComposeButCss" @click="copyEvent(row)">复制</a>
                <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(row, '同步')">同步</a>
                <a class="appComposeButCss borderNoneCss" @click="deleteEvent(row)">
                  删除
                </a>
              </td>
            </template>
          </mu-data-table>
        </div>
        <div v-if="xsfsType === '视图'">
          <div class="appComposeItemCss" v-for="(item, index) in showlist" :key="index">
            <div class="appComposeImgCss">
              <div @click="editEvent(item.id)">
                <img v-if="viewLock !== item.id && item.showImg" :src="
                  head + `://${appComposeImgUrl}/cover/` +
                  item.id +
                  '.png?rand=' +
                  parseInt(10 * Math.random())
                " />
              </div>
              <label :for="'cover' + index">
                <mu-icon size="18" value="perm_media" color="grey50"></mu-icon>
              </label>
              <input v-show="false" type="file" :id="'cover' + index" @change="coverEdit($event, item.id)" accept=".png" />
            </div>
            <div class="appComposeInfoCss">
              <div class="appComposeCheckCss">
                <div class="appComposeCheckboxCss"></div>
              </div>
              <div class="appComposeNameCss">{{ item.name }}</div>
              <div class="appComposeIdCss">ID: {{ item.id }}</div>
            </div>
            <div class="appComposeFootCss">
              <a class="appComposeButCss" :style="item.release ? 'cursor:not-allowed;color:#c9c9c9' : ''"
                @click="releaseEvent(item, true)">发布</a>
              <a class="appComposeButCss" @click="viewEvent(item.id)">运行</a>
              <a class="appComposeButCss" @click="editEvent(item.id)">编辑</a>
              <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(item, '映射')">映射</a>
              <a class="appComposeButCss" :style="item.release ? '' : 'cursor:not-allowed;color:#c9c9c9'"
                @click="releaseEvent(item, false)">下架</a>
              <a class="appComposeButCss" @click="updateEvent(item)">设置</a>
              <a class="appComposeButCss" @click="copyEvent(item)">复制</a>
              <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(item, '同步')">同步</a>
              <a class="appComposeButCss borderNoneCss" @click="deleteEvent(item)">
                删除
              </a>
            </div>
          </div>
        </div>
        
      </div>
    </mu-fade-transition>

    <!-- 待发布组态 -->
    <mu-fade-transition>
      <div class="appComposeHeadCss" v-if="appMenuIdx == 1">
        <input type="text" placeholder="组态名称" class="layui-input" v-model="zutaiNameSearch" @keyup.enter="searchZutai" />
        <mu-button fab small color="primary" @click="searchZutai">
          <mu-icon size="22" value="search"></mu-icon>
        </mu-button>
        <mu-button round color="primary" @click="createEdit">
          创建组态
        </mu-button>
        <div class="xsfsCss">
          <mu-button small :color="xsfsType === '列表' ? 'primary': ''" @click="xsfsSet('列表')">
            列表
          </mu-button>
          <mu-button small :color="xsfsType === '视图' ? 'primary': ''" @click="xsfsSet('视图')">
            视图
          </mu-button>
        </div>
      </div>
    </mu-fade-transition>
    <mu-fade-transition>
      <div class="appComposeCss" v-if="appMenuIdx == 1">
        <div v-if="xsfsType === '列表'">
          <mu-data-table stripe :columns="titleList" :data="showlist">
            <template slot-scope="{row}">
              <td class="is-center">
                <img class="tableImgCss" v-if="viewLock !== row.id && row.showImg" :src="
                  head + `://${appComposeImgUrl}/cover/` +
                  row.id +
                  '.png?rand=' +
                  parseInt(10 * Math.random())
                " />
              </td>
              <td class="is-center">{{row.name}}</td>
              <td class="is-center">{{row.id}}</td>
              <td>
                <a class="appComposeButCss" :style="row.release ? 'cursor:not-allowed;color:#c9c9c9' : ''"
                  @click="releaseEvent(row, true)">发布</a>
                <a class="appComposeButCss" @click="viewEvent(row.id)">运行</a>
                <a class="appComposeButCss" @click="editEvent(row.id)">编辑</a>
                <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(row, '映射')">映射</a>
                <a class="appComposeButCss" :style="row.release ? '' : 'cursor:not-allowed;color:#c9c9c9'"
                  @click="releaseEvent(row, false)">下架</a>
                <a class="appComposeButCss" @click="updateEvent(row)">设置</a>
                <a class="appComposeButCss" @click="copyEvent(row)">复制</a>
                <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(row, '同步')">同步</a>
                <a class="appComposeButCss borderNoneCss" @click="deleteEvent(row)">
                  删除
                </a>
              </td>
            </template>
          </mu-data-table>
        </div>
        <div v-if="xsfsType === '视图'">
          <div class="appComposeItemCss" v-for="(item, index) in showlist" :key="index">
            <div class="appComposeImgCss">
              <div class="aCImgCss" @click="editEvent(item.id)">
                <img v-if="viewLock !== item.id && item.showImg" :src="
                  head + `://${appComposeImgUrl}/cover/` +
                  item.id +
                  '.png?rand=' +
                  parseInt(10 * Math.random())
                " />
              </div>
              <label :for="'cover' + index">
                <mu-icon size="18" value="perm_media" color="grey50"></mu-icon>
              </label>
              <input v-show="false" type="file" :id="'cover' + index" @change="coverEdit($event, item.id)" accept=".png" />
            </div>
            <div class="appComposeInfoCss">
              <div class="appComposeCheckCss">
                <div class="appComposeCheckboxCss"></div>
              </div>
              <div class="appComposeNameCss">{{ item.name }}</div>
              <div class="appComposeIdCss">ID: {{ item.id }}</div>
            </div>
            <div class="appComposeFootCss">
              <a class="appComposeButCss" :style="item.release ? 'cursor:not-allowed;color:#c9c9c9' : ''"
                @click="releaseEvent(item, true)">发布</a>
              <a class="appComposeButCss" @click="viewEvent(item.id)">运行</a>
              <a class="appComposeButCss" @click="editEvent(item.id)">编辑</a>
              <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(item, '映射')">映射</a>
              <a class="appComposeButCss" :style="item.release ? '' : 'cursor:not-allowed;color:#c9c9c9'"
                @click="releaseEvent(item, false)">下架</a>
              <a class="appComposeButCss" @click="updateEvent(item)">设置</a>
              <a class="appComposeButCss" @click="copyEvent(item)">复制</a>
              <a class="appComposeButCss borderNoneCss" @click="mappingOrSyncEvent(item, '同步')">同步</a>
              <a class="appComposeButCss borderNoneCss" @click="deleteEvent(item)">
                删除
              </a>
            </div>
          </div>
        </div>
      </div>
    </mu-fade-transition>

    <mu-fade-transition>
      <div class="app-material-head appComposeHeadCss" v-if="appMenuIdx == 2">
        <div style="
            height: 50px;
            padding-left: 20px;
            line-height: 50px;
            font-size: 14px;
            font-weight: 700;
            color: #666;
          ">
          素材总数: {{ materialPrivate.length }}
        </div>
        <label for="materialUpload" class="materialUpload"> 上传素材 </label>
        <input id="materialUpload" v-show="false" type="file" @change="uploadEdit($event)"
          accept=".gif,.png,.jpeg,.jpg,.svg" />
      </div>
    </mu-fade-transition>
    <mu-fade-transition>
      <div class="app-material-private" v-if="appMenuIdx == 2">
        <div v-if="viewLock" style="
                                                          position: relative;
                                                          width: 100%;
                                                          padding: 10px;
                                                          overflow: hidden;
                                                        ">
          <div class="material-item" v-for="(url, idx) in materialPrivate" :key="idx">
            <div class="img"><img :src="urlHandle(url)" /></div>
            <div class="btn">
              <div @click="materialDelete(url)">
                <mu-icon size="20" value="delete" style="margin: 14px 0"></mu-icon>
                移除
              </div>
              <a :href="urlHandle(url)" download="" target="_blank">
                <mu-icon size="20" value="find_in_page" style="margin: 14px 0"></mu-icon>
                查看
              </a>
            </div>
          </div>
        </div>
      </div>
    </mu-fade-transition>

    <mu-fade-transition>
      <div class="app-material-nav" v-if="appMenuIdx == 3">
        <div class="name">素材类型：</div>
        <div class="url" v-for="(item, index) in materialLabel" :key="index"
          :style="materialIdx == index ? 'border: 1px solid #108CEE' : ''" @click="materialClassClick(index)">
          {{ item }}
        </div>
      </div>
    </mu-fade-transition>
    <mu-fade-transition>
      <div class="app-material-public" v-if="appMenuIdx == 3">
        <div v-if="viewLock" style="
                                                          position: relative;
                                                          width: 100%;
                                                          padding: 10px;
                                                          overflow: hidden;
                                                        ">
          <div class="material-item" v-for="(url, idx) in materialList" :key="idx">
            <div class="img"><img :src="urlHandle(url)" /></div>
            <div class="btn">
              <div @click="materialSave(url)">
                <mu-icon size="20" value="open_in_browser" style="margin: 14px 0"></mu-icon>
                添加
              </div>
              <a :href="urlHandle(url)" download="" target="_blank">
                <mu-icon size="20" value="find_in_page" style="margin: 14px 0"></mu-icon>
                查看
              </a>
            </div>
          </div>
        </div>
      </div>
    </mu-fade-transition>

    <mu-fade-transition>
      <div class="app-material-head appComposeHeadCss" v-if="appMenuIdx === 4">
        <div style="
            height: 50px;
            padding-left: 20px;
            line-height: 50px;
            font-size: 14px;
            font-weight: 700;
            color: #666;
          ">
          音频素材总数: {{ audioMsgList.length }}
        </div>
        <label for="materialUpload" class="materialUpload"> 上传素材 </label>
        <input id="materialUpload" v-show="false" type="file" @change="uploadAudioEdit($event)"
          accept=".mp3" />
      </div>
    </mu-fade-transition>
    <mu-fade-transition>
      <div class="app-material-private" v-if="appMenuIdx === 4">
        <div v-if="viewLock" style="
                                                          position: relative;
                                                          width: 100%;
                                                          padding: 10px;
                                                          overflow: hidden;
                                                        ">
          <div class="material-item" v-for="(item, idx) in audioMsgList" :key="idx">
            <div class="img">{{ item.name }}</div>
            <div class="btn">
              <div @click="audioDelete(item.url)">
                <mu-icon size="20" value="delete" style="margin: 14px 0"></mu-icon>
                移除
              </div>
              <a :href="urlHandle(item.url)" download="" target="_blank">
                <mu-icon size="20" value="find_in_page" style="margin: 14px 0"></mu-icon>
                查看
              </a>
            </div>
          </div>
        </div>
      </div>
    </mu-fade-transition>

    <mu-dialog :title="dialogTitle" width="400" scrollabel :open.sync="showDialog">
      <div v-if="dialogTitle === '同步画面'">
        <div class="selectStreamOrDynaltContentCss">
          <select class="selectStreamOrDynaltCss" v-model="form.dynalt" @change="dynaltChange">
            <option v-for="(item, index) in dynaltList" :key="index" :value="item">
              {{ item.name }}
            </option>
          </select>
          <input v-model="selectDynaltInput" placeholder="请选择画面" class="rightNavSelectInputCss" @change="selectInputChange" />
        </div>
      </div>
      <div class="selectStreamOrDynaltContentCss">
        <select class="selectStreamOrDynaltCss" v-model="form.stream" @change="streamChange">
          <option v-for="(item, index) in streamList" :key="index" :value="item">
            {{ item.name }}
          </option>
        </select>
        <input v-model="selectStreamInput" placeholder="请选择数据流" class="rightNavSelectInputCss" @change="selectInputChange" />
      </div>
      <mu-button slot="actions" flat color="primary" @click="sureMappingOrSync">确定</mu-button>
      <mu-button slot="actions" flat color="default" @click="showDialog = false">取消</mu-button>
    </mu-dialog>
    
  </div>
</template>

<script>
import CreateEdit from "../components/Create.vue"
import UpdateEdit from "../components/Update.vue"
import {
  dynalt_SetToken,
  dynalt_Auth,
  dynalt_List2,
  dynalt_Update,
  dynalt_Delete,
  dynalt_Create,
  dynalt_Material_Label,
  dynalt_Material_List,
  dynalt_Material_Save,
  dynalt_Material_Private,
  dynalt_Material_Delete,
  dynalt_Material_Upload,
  dynalt_Audio_List,
  dynalt_Audio_Delete,
  dynalt_Audio_Upload,
  dynalt_Material_Cover,
  dynalt_SetUrl,
  dynalt_Retrieve
} from "../network/dynalt.js";
import { login, tolink_SetToken, tolink_SetUrl, tolink_Stream, tolink_Label } from "../network/tolink.js";
import { getUrlKey } from "../assets/option.js";
import { setPictureList } from '../utils/yzt.js'

const lodash = require('lodash')

export default {
  data() {
    return {
      pageName: 'ToLink',
      pageTitle: '云组态',

      tolinkToken: "",
      dynaltToken: "",
      userid: null,
      ok: false,

      appMenuIdx: 0,
      appComposePage: 1,
      showDynaltList: [], // 显示出的组态列表
      titleList: [ // 组态列表标题
        { title: '封面', name: 'logo', align: 'center', class: 'tableTitleCss' },
        { title: '组态名', name: 'name', align: 'center', class: 'tableTitleCss' },
        { title: '组态id', name: 'id', align: 'center', class: 'tableTitleCss' },
        { title: '操作', name: 'operate', align: 'center', class: 'tableTitleCss' }
      ],
      showlist: [],

      materialIdx: 0,
      materialLabel: [],
      materialList: [],
      viewLock: false,
      head: "https",

      materialPrivate: [],

      audioMsgList: [], // 音频素材文件列表

      zutaiNameSearch: "", // 查询组态名称

      appComposeImgUrl: `dynalt.ditoo.com.cn`,

      dialogTitle: '', // 弹框标题
      showDialog: false, // 是否显示弹框
      dynaltMsg: {}, // 被映射的组态图信息
      allStreamList: [], // 全部数据流列表
      streamList: [], // 数据流列表
      form: {
        stream: null,
        dynalt: null
      },
      selectStreamInput: '', // 选择的输入框数据

      selectDynaltInput: '', // 选择画面的输入框
      dynaltList: [], // 组态画面列表

      xsfsType: '列表' // 显示方式类型
    }
  },
  watch: {
    appMenuIdx() {
      switch (this.appMenuIdx) {
        case 0:
        case 1:
          this.updaeShowList();
          break;
        case 2:
          this.dynaltMaterialPrivate();
          break;
        case 3:
          this.dynaltMaterialLabel();
          break;
        case 4:
          this.dynaltAudioPrivate() // 获取音频素材列表
          break;
      }
    },
    showDynaltList() {
      this.updaeShowList()
    },
    materialIdx() {
      this.dynaltMaterialList(this.materialLabel[this.materialIdx])
    },
  },
  mounted() {
    this.tolinkToken = getUrlKey('token')
    this.userid = getUrlKey('userid')
    this.setToken()
  },
  methods: {
    async setToken() {
      if (!this.tolinkToken && process.env.NODE_ENV === 'development') { // 开发模式
        this.userid = `tolink.cd-kght.cn` // 空港环境
        // this.userid = `10.30.201.240:83` // 中自环保内网
        // this.userid = `dos.tolinkyun.com:9010` // 蒙毅服务器域名
        // this.userid = '192.168.0.68:83' // 蒙毅龙头石
        // this.userid = 'tolink.schcec.com' // 蓉城汉昌
        // this.userid = 'dicloud.tolink.ditoo.com.cn' // 公有云平台（贵州）
        // this.userid = 'cdz.zxfhgroup.com:83' // 龙头石私有服务
        // this.userid = '172.16.11.11:83' // 天元重工
        // this.userid = '110.185.210.70:8880' // 盛帮
        this.userid = '223.87.76.70:83' // 众鑫盛外网
      }
      
      // https处理 //
      if (this.userid == "tolink.ditoo.com.cn") {
        this.userid = "business.ditoo.com.cn";
      }
      if (this.userid == "iot.lbyun.cn") {
        this.userid = "manager.lbyun.cn";
      }
      if (this.userid == "dicloud.tolink.ditoo.com.cn") {
        this.userid = "dicloud.ditoo.com.cn";
      }
      if (this.userid == "tolink.scnee-cloud.com") {
        this.userid = "scnee-cloud.com";
      }
      if (this.userid == "tolink.hsqc.ditoo.com.cn") {
        this.userid = "hsqc.ditoo.com.cn";
      }
      if (this.userid == "tolink.kghj.tolinkyun.com") { // 空港环境
        this.userid = "kghj.tolinkyun.com";
      }
      if (this.userid == "tolink.hsqc.website") {
        this.userid = "hsqc.website";
      }
      if (this.userid == "tolink.cd-kght.cn") {
        this.userid = "cd-kght.cn"
      }
      if (this.userid == "223.87.76.70:83") {
        this.pageName = '云作图平台'
        this.pageTitle = '免费版(容量:10)'
      }

      if (this.userid.indexOf(':') !== -1) { // 采集云使用的是ip，不是域名
        this.head = 'http'
        tolink_SetUrl('http://' + this.userid)
        if (this.userid === `10.30.201.240:83` || // 中自采集云
          this.userid === '10.30.201.240:80' || // 中自业务云
          this.userid === '10.30.201.240:8000') { // 中自环保ip（10.30.201.240为运行环境ip）
          dynalt_SetUrl('http://' + '10.30.201.240:8000') // 中自环保本地ip
          this.appComposeImgUrl = '10.30.201.240:8000'
        } else if(this.userid === `dos.tolinkyun.com:9010`) { // 蒙毅服务器域名
          dynalt_SetUrl('http://dos.tolinkyun.com:9010')
        } else if(this.userid === `192.168.0.68:80` || this.userid === `192.168.0.68:83`) { // 蒙毅龙头石域名
          dynalt_SetUrl('http://192.168.0.68:8001')
        } else if(this.userid === `cdz.zxfhgroup.com:8003` || this.userid === `cdz.zxfhgroup.com:83`) { // 内网龙头石
          this.head = 'https'
          dynalt_SetUrl('https://cdz.zxfhgroup.com:8001')
          tolink_SetUrl('https://' + this.userid)
          this.appComposeImgUrl = 'cdz.zxfhgroup.com:8000'
        } else if(this.userid === `172.16.11.11:80` || this.userid === `172.16.11.11:83`) { // 天元重工域名
          dynalt_SetUrl('http://172.16.11.11:8001')
        } else if(this.userid === `10.0.3.165:80` || this.userid === `10.0.3.165:83`) { // 浙江永贵域名
          dynalt_SetUrl('http://10.0.3.165:8001')
        } else if(this.userid === `223.87.76.70:80` || this.userid === `223.87.76.70:83`) { // 众鑫盛外网
          // dynalt_SetUrl('http://' + '223.87.76.70:83')
          // dynalt_SetUrl('http://223.87.76.70:8001')
        } else if(this.userid === `192.168.1.33:80` || this.userid === `192.168.1.33:83`) { // 万友滤机域名
          dynalt_SetUrl('http://192.168.1.33:8001')
        } else if(this.userid === `iot.kaisaier.com.cn:8003` || this.userid === `iot.kaisaier.com.cn:83`) { // 凯赛尔域名
          dynalt_SetUrl('https://iot.kaisaier.com.cn:8001')
          tolink_SetUrl('https://' + 'iot.kaisaier.com.cn:83')
        } else if(this.userid === `192.168.0.146:80` || this.userid === `192.168.0.146:83`) { // 测试服务域名
          dynalt_SetUrl('http://192.168.0.146:8001')
        } else if(this.userid === `localhost`) { // 简晟打包
          dynalt_SetUrl('http://localhost:83')
        } else if(this.userid === `110.185.210.70:8880` || this.userid === `110.185.210.70:8881`) { // 盛帮
          this.appComposeImgUrl = '110.185.210.70:8882/dynalt'
          dynalt_SetUrl('http://' + '110.185.210.70:8882')
          tolink_SetUrl('http://' + '110.185.210.70:8880')
          if (location.hostname === '192.168.0.251') { // 盛帮内网
            this.appComposeImgUrl = '192.168.0.251:8882/dynalt'
            dynalt_SetUrl('http://' + '192.168.0.251:8882')
            tolink_SetUrl('http://' + '192.168.0.251:8880')
          }
        } else if(this.userid === `111.9.53.181:8004` || this.userid === `111.9.53.181:8003`) { // 锂硫电池朔源平台
          dynalt_SetUrl('http://111.9.53.181:8001')
        } else {
          dynalt_SetUrl('http://dynalt2.ditoo.com.cn')
        }
      } else {
        if (this.userid === `tolink.schcec.com`) { // 蓉城汉昌
          dynalt_SetUrl('http://dynalt.schcec.com')
        } else {
          tolink_SetUrl('https://' + this.userid)
        }
      }
      if (!this.tolinkToken) {
        await this.loginTolinkToken() // 登录获取采集云token
      }
      tolink_SetToken(this.tolinkToken)
      // -- -- -- //

      this.dynaltAuth(this.tolinkToken, this.userid);

      let interval = setInterval(() => {
        if (
          this.tolinkToken != "" &&
          this.userid != null &&
          this.dynaltToken != ""
        ) {
          clearInterval(interval);
          this.getDynaltList();
          this.ok = true;
        }
      }, 500);
    },
    async loginTolinkToken() { // 登录获取采集云token
      let username = 'ditoo'
      let password = 'ditoo_password'
      switch (this.userid) {
        case '10.30.201.240:83': // 中自环保内网
          username = '周天皓'
          password = 'Hao8981409'
        break
        case 'cd-kght.cn': 
          username = 'ditooadmin'
          password = 'admin_password'
        break
      }
      const res = await login({ // 使用帝图采集云的账号密码
        username,
        password
      })
      this.tolinkToken = `Bearer ${res.data.return.token}`
    },
    urlHandle(url) {
      if (typeof(url) === 'string') {
        switch (this.head) {
          case 'http':
            return url.replace(/https/g, 'http')
        }
      }
      return url
    },
    updaeShowList() {
      this.showlist = [];
      for (let item of this.showDynaltList) {
        switch (this.appMenuIdx) {
          case 0:
            if (item.release) {
              this.showlist.push(item);
            }
            break;
          case 1:
            if (!item.release) {
              this.showlist.push(item);
            }
            break;
        }
      }
      setTimeout(() => {
        this.showDynaltList.forEach(item => {
          item.showImg = true
        })
      }, 500)
      return this.showlist;
    },
    searchZutai() {
      this.showlist = [];
      const showlist = [];
      for (let item of this.showDynaltList) {
        switch (this.appMenuIdx) {
          case 0:
            if (item.release) {
              showlist.push(item);
            }
            break;
          case 1:
            if (!item.release) {
              showlist.push(item);
            }
            break;
        }
      }
      showlist.forEach((item) => {
        if (item.name.indexOf(this.zutaiNameSearch) !== -1) {
          this.showlist.push(item);
        }
      });
    },
    xsfsSet(type) { // 显示方式类型
      this.xsfsType = type
    },
    async releaseEvent(item, val) {
      const dynaltMsg = await this.getDynaltDetailMsg(item.id) // 获取组态图详细配置信息
      if (dynaltMsg) {
        item = dynaltMsg
      }
      let msg = val ? "发布" : "下架";
      this.$confirm("确定要" + msg + "？", "提示", {}).then(({ result }) => {
        if (result) {
          let item_c = lodash.cloneDeep(item);
          item_c.release = val;
          this.dynaltUpdate(item_c);
        }
      });
    },
    appMenuClick(idx) {
      this.appMenuIdx = idx;
    },
    createEdit() {
      console.log(this.showDynaltList)
      if (this.showDynaltList.length >= 10) {
        alert('图数量不能超过10个')
        return
      }
      this.$layer.iframe({
        content: {
          content: CreateEdit,
          parent: this,
          data: {
            success: this.getDynaltList,
          },
        },
        area: ["450px", "450px"],
        title: "创建组态",
        cancel: () => { },
      });
    },
    async updateEvent(item) { // 设置
      const dynaltMsg = await this.getDynaltDetailMsg(item.id) // 获取组态图详细配置信息
      if (dynaltMsg) {
        item = dynaltMsg
      }
      this.$layer.iframe({
        content: {
          content: UpdateEdit,
          parent: this,
          data: {
            form: item,
            success: () => {
              this.getDynaltList()
            }
          }
        },
        area: ["450px", "450px"],
        title: "设置组态",
        cancel: () => { },
      });
    },
    deleteEvent(row) {
      this.$confirm(`确定要删除 ${row.name}？`, '提示', {}).then(({ result }) => {
        if (result) {
          this.dynaltDelete(row.id);
        }
      });
    },
    async copyEvent(item) { // 复制
      const dynaltMsg = await this.getDynaltDetailMsg(item.id) // 获取组态图详细配置信息
      if (dynaltMsg) {
        item = dynaltMsg
      }
      this.$confirm("确定要复制此应用？", "提示", {}).then(({ result }) => {
        if (result) {
          let item_c = lodash.cloneDeep(item);
          item_c.name += "（复制）";
          item_c.public = true // 是否不使用查看权限密码
          item_c.password = ''
          item_c.usecontrolpwd = true // 是否不使用控制权限密码
          item_c.controlpassword = ''
          this.dynaltCreate(item_c) // 创建组态图
        }
      });
    },
    viewEvent(id) {
      window.open(
        `#/board?id=${id}&userid=${this.userid}`,
        "_blank"
      );
    },
    editEvent(id) {
      let routeUrl = this.$router.resolve({
        path: "/edit",
        query: {
          id,
          userid: this.userid,
          tolinkToken: this.tolinkToken,
          dynaltToken: this.dynaltToken,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    async mappingOrSyncEvent(item, type) { // 映射或同步组态图
      this.selectStreamInput = '' // 选择的输入框数据
      this.selectDynaltInput = '' // 选择画面的输入框
      this.dynaltList = this.showDynaltList
      
      this.dialogTitle = `${type}画面`
      const dynaltMsg = await this.getDynaltDetailMsg(item.id) // 获取组态图详细配置信息
      if (dynaltMsg) {
        this.dynaltMsg = dynaltMsg
        const streamRes = await tolink_Stream() // 获取数据流列表
        const streamMsg = streamRes.data.return.stream
        if (streamMsg) {
          this.allStreamList = streamMsg // 全部数据流
          this.streamList = streamMsg
          this.showDialog = true
        } else {
          this.$toast.error("获取数据流列表失败")
        }
      } else {
        this.$toast.error("获取组态信息失败")
      }
    },
    async getDynaltDetailMsg(id) { // 获取组态图详细配置信息
      const res = await dynalt_Retrieve(id) // 获取组态图配置信息
      const dynaltMsg = res.data.return.dynalt
      return dynaltMsg
    },
    selectInputChange() { // 选择框获取焦点事件
      if (this.dialogTitle === '同步画面') {
        const dynaltList = []
        this.showDynaltList.forEach(item => {
          if (item.name.indexOf(this.selectDynaltInput) !== -1) {
            dynaltList.push(item)
          }
        })
        this.dynaltList = dynaltList
      }
      const streamList = [] // 显示出的数据流
      this.allStreamList.forEach(item => {
        if (item.name.indexOf(this.selectStreamInput) !== -1) {
          streamList.push(item)
        }
      })
      this.streamList = streamList
    },
    streamChange() { // 数据流选择改变
      this.selectStreamInput = this.form.stream.name
    },
    async dynaltChange() { // 画面选择改变
      this.selectDynaltInput = this.form.dynalt.name
      const dynaltMsg = await this.getDynaltDetailMsg(this.form.dynalt.id) // 获取组态图详细配置信息
      if (dynaltMsg) {
        this.form.dynalt = dynaltMsg
      } else {
        this.$toast.error("获取组态信息失败")
      }
    },
    async sureMappingOrSync() { // 确定映射
      if (this.form.stream) {
        let item_c = {}
        if (this.dialogTitle === '映射画面') {
          this.$toast.success('映射中，不要关闭页面，请等待...')
          item_c = lodash.cloneDeep(this.dynaltMsg) // 当前被映射的画面数据
          item_c.name = this.form.stream.name // 赋值当前选择映射的数据流名称
        } else if (this.dialogTitle === '同步画面') {
          this.$toast.success('同步中，不要关闭页面，请等待...')
          item_c = lodash.cloneDeep(this.form.dynalt) // 当前被同步的画面数据
          item_c.name = this.dynaltMsg.name // 赋值当前画面的名称
        }
        const canvas = item_c.config.canvas
        if (canvas) {
          for(let i = 0; i < canvas.length; i++) {
            const canvasItem = canvas[i]
            const element = canvasItem.element
            if (element) {
              for (let j = 0; j < element.length; j++) {
                const elementItem = element[j]
                if (elementItem.value) { // value是否有值
                  const value = elementItem.value
                  if (value.stream) { // value是否绑定了数据流
                    value.stream = this.form.stream
                    const label = value.label
                    if (label && label.name) { // value是否绑定了数据标签
                      let nameMsg = ''
                      if (label.name.indexOf('-') !== -1) { // 有'-'间隔
                        const labelNameIndex = label.name.indexOf('-')
                        nameMsg = label.name.slice(labelNameIndex + 1)
                      } else {
                        nameMsg = label.name
                      }
                      if (nameMsg && nameMsg !== '') { // 是可以复制的标签
                        const labelListRes = await tolink_Label(this.form.stream['api-key'])
                        const list = labelListRes.data.return.label.list
                        if (list) {
                          let newnameMsg = ''
                          let newlabel = {}
                          list.forEach(labelItem => {
                            newlabel = labelItem
                            if (labelItem.name.indexOf('-') !== -1) { // 有'-'间隔
                              const labelItemNameIndex = labelItem.name.indexOf('-')
                              newnameMsg = labelItem.name.slice(labelItemNameIndex + 1)
                            } else {
                              newnameMsg = labelItem.name
                            }
                            if (nameMsg === newnameMsg) { // 标签后缀名相等
                              value.label = newlabel
                            }
                          })
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (this.dialogTitle === '同步画面') {
          await dynalt_Delete(this.dynaltMsg.id) // 删除当前组态图
        }
        const newDynalt = await dynalt_Create(item_c) // 创建组态图
        if (newDynalt.data.code === 200) {
          this.getDynaltList()
        }
        this.$toast.success(newDynalt.data.msg)
      } else {
        this.$toast.warning("请选择数据流")
      }
      this.showDialog = false
    },
    getDynaltList() { // 获取组态图列表
      this.viewLock = false;
      dynalt_List2()
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list === null) {
              list = []
            }
            const showDynaltList = []
            let nameString = ''
            list.forEach(item => {
              item.showImg = false
              showDynaltList.push(item)
              nameString += item.name + ', '
            })
            this.showDynaltList = showDynaltList
            this.dynaltList = showDynaltList
            this.viewLock = true
          } else {
            this.$toast.error("获取列表失败")
          }
        })
        .catch(this.catchErr)
    },
    dynaltUpdate(data) {
      this.viewLock = false
      dynalt_Update(data)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success(res.data.msg)
            this.getDynaltList()
            this.viewLock = true
          } else {
            this.$toast.error(res.data.msg)
          }
        })
        .catch(this.catchErr)
    },
    dynaltDelete(id) { // 删除组态图
      this.viewLock = false;
      dynalt_Delete(id)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success(res.data.msg);
            this.getDynaltList();
            this.viewLock = true;
          } else {
            this.$toast.error(res.data.msg);
          }
        })
        .catch(this.catchErr);
    },
    dynaltCreate(item) { // 创建组态图
      this.viewLock = false;
      dynalt_Create(item)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success(res.data.msg);
            this.getDynaltList();
            this.viewLock = true;
          } else {
            this.$toast.error(res.data.msg);
          }
        })
        .catch(this.catchErr);
    },

    /* - - - - - - - */

    coverEdit(event, id) {
      let fileType = event.target.files[0].name.split(".").pop().toLowerCase();

      setTimeout(() => {
        this.dynaltMaterialCover(id, fileType, event.target.files[0]);
      }, 1000);
    },
    uploadEdit(event) {
      let fileType = event.target.files[0].name.split(".").pop().toLowerCase();

      setTimeout(() => {
        this.dynaltMaterialUpload(fileType, event.target.files[0]);
      }, 1000);
    },
    uploadAudioEdit(event) { // 上传音频素材
      const name = event.target.files[0].name
      const file = event.target.files[0]
      setTimeout(() => {
        this.dynaltAudioUpload(name, file)
      }, 1000);
    },
    materialClassClick(idx) {
      this.materialIdx = idx;
    },
    materialSave(url) {
      this.$confirm("添加到我的素材库？", "提示", {}).then(({ result }) => {
        if (result) {
          this.dynaltMaterialSave(url);
        }
      });
    },
    materialDelete(url) {
      this.$confirm("从列表中删除？", "提示", {}).then(({ result }) => {
        if (result) {
          this.dynaltMaterialDelete(url);
        }
      });
    },

    dynaltAuth(token, userid) {
      dynalt_Auth(token, userid)
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return != null) {
              this.dynaltToken = res.data.return.token;
              dynalt_SetToken(this.dynaltToken);
            }
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialLabel() {
      dynalt_Material_Label()
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.return.list == null) {
              res.data.return.list = [];
            }
            this.materialLabel = res.data.return.list;
            this.dynaltMaterialList(this.materialLabel[this.materialIdx]);
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialList(label) {
      this.viewLock = false;
      dynalt_Material_List(label)
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            this.materialList = setPictureList(this, list)
            this.viewLock = true;
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialSave(url) {
      dynalt_Material_Save(url)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("添加成功");
          } else {
            this.$toast.error("添加失败");
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialPrivate() {
      this.viewLock = false;
      dynalt_Material_Private()
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            this.materialPrivate = setPictureList(this, list)
            this.viewLock = true;
          }
        })
        .catch(this.catchErr);
    },
    dynaltAudioPrivate() { // 获取音频素材列表
      dynalt_Audio_List()
        .then(res => {
          if (res.data.code == 200) {
            let list = res.data.return.list
            if (list == null) {
              list = []
            }
            const audioMsgList = []
            list.forEach(url => {
              const lastIndex = url.lastIndexOf('/') // '/'最后一次出现的位置
              audioMsgList.push({
                url,
                name: url.slice(lastIndex + 1)
              })
            })
            
            this.audioMsgList = audioMsgList
          }
        })
        .catch(this.catchErr);
    },
    dynaltMaterialDelete(url) {
      dynalt_Material_Delete(url)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("删除成功");
          } else {
            this.$toast.error("删除失败");
          }
          this.dynaltMaterialPrivate();
        })
        .catch(this.catchErr);
    },
    dynaltMaterialUpload(fileType, file) {
      let param = new FormData();
      param.append("file", file);
      param.append("filetype", fileType);
      dynalt_Material_Upload(param)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("上传成功");
          } else {
            this.$toast.error("上传失败");
          }
          this.dynaltMaterialPrivate();
        })
        .catch(this.catchErr);
    },
    audioDelete(url) { // 删除音频素材
      dynalt_Audio_Delete(url)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("删除成功");
          } else {
            this.$toast.error("删除失败");
          }
          this.dynaltAudioPrivate() // 获取音频素材列表
        })
        .catch(this.catchErr);
    },
    dynaltAudioUpload(name, file) {
      let param = new FormData()
      param.append('name', name)
      param.append('file', file)
      dynalt_Audio_Upload(param)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("上传成功");
          } else {
            this.$toast.error("上传失败");
          }
          this.dynaltAudioPrivate() // 获取音频素材列表
        })
        .catch(this.catchErr);
    },
    dynaltMaterialCover(id, fileType, file) {
      let param = new FormData();
      param.append("id", id);
      param.append("file", file);
      param.append("filetype", fileType);
      this.viewLock = id;
      dynalt_Material_Cover(param)
        .then(res => {
          if (res.data.code == 200) {
            this.$toast.success("修改成功");
            this.viewLock = true;
          } else {
            this.$toast.error("修改失败");
          }
        })
        .catch(this.catchErr);
    },

    /* - - - - - - - */

    catchErr(err) {
      console.log(err);
      this.$toast.error("网络请求异常");
    },
  },
};
</script>

<style lang="stylus" scoped>
#studio {
  position: relative;
  height: 100%;
  width: 100%;
  background: #ebebeb;
}

.app-head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 100;
  background: #373d41;
  padding: 0 20px;
  .app-logo {
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
  }
  .versionCss {
    float right 
    margin-right 30px
    height 50px
    line-height 50px
    color #fff
  }
}

.app-search {
  float: right;
  display: flex;
  height: 30px;
  margin: 9px 0;
  margin-right: 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: #fff;
}

.app-search>div {
  background: rgb(141, 141, 141);
  border-radius: 4px;
}

.app-search>div:hover {
  background: rgb(104, 104, 104);
}

.app-search>div:active {
  background: rgb(141, 141, 141);
  user-select: none;
}

.app-search>div img {
  height: 14px;
  width: 14px;
  margin: 6px;
}

.app-search input {
  height: 100%;
  width: 130px;
  border: none;
  padding: 0 10px;
  margin: none;
  outline: none;
}

.app-menu {
  position: absolute;
  z-index: 19970801;
  top: 50px;
  left: 0;
  width: 240px;
  bottom: 0;
  background-color: #222d32;
  border-right: 1px solid #ebebeb;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-menu-name {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #eee;
  border-bottom: 1px solid #e8ecf0;
  padding-left: 30px;
}

.app-menu-app {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  font-size: 14px;
  color: #eee;
  overflow: hidden;
  user-select: none;
}

.app-menu-app-cur {
  background: #1a2427;
  color: #108cee;
}

.app-menu-app:hover {
  background: #1a2427;
}

.app-menu-area {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background: #108cee;
}

.app-create {
  float: right;
  height: 30px;
  line-height: 28px;
  margin: 9px 0;
  padding: 0 14px;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  color: #ffffffb3;
  user-select: none;
}

.app-create:hover {
  color: #fff;
}

.app-create:active {
  color: #1a2427;
}

.appComposeCss {
  position: absolute;
  top: 100px;
  left: 250px;
  padding-top 10px
  height: calc(100% - 100px);
  width: calc(100% - 250px);
  overflow-y: scroll;
  background: #fff;
  .tableImgCss {
    width 160px
    height 90px
  }
}

.appComposeHeadCss {
  display: flex;
  position: absolute;
  top: 50px;
  left: 250px;
  width: calc(100% - 250px);
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #e8ecf0;
  .xsfsCss {
    position absolute
    top 0
    right 100px
  }
}

.appComposeHeadCss .mu-button {
  width: 50px;
  height: 32px;
  margin: 8px 10px 8px 10px;
}

#studio .appComposeHeadCss .mu-button-small {
  height: 32px;
  width: 32px;
}

.layui-input {
  height: 32px;
  border: none;
  padding: 0 10px;
  margin: 8px 0 8px 10px;
  outline: none;
  border: 1px solid #e8ecf0;
  font-size: 12px;
}

.appComposeItemCss {
  float: left;
  width: 238px;
  height: 320px;
  margin 0 0 10px 10px
  border-radius: 1px;
  border: 1px solid #d7d7d7;
  .detailMsgLoadingCss {
    width 100%
    line-height 100px
    color #25ACFD
    text-align center
  }
}

.appComposeImgCss {
  position: relative;
  width: 100%;
  height: 160px;
  .aCImgCss {
    width 100%
    height 100%
    img {
      width 100%
      height 100%
    }
  }
}

.appComposeImgCss>label {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  padding: 5px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 0 0 4px;
}

.appComposeImgCss svg {
  width: 100%;
  height: 100%;
}

.appComposeInfoCss {
  height: 58px;
  width: 100%;
  padding-right: 8px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.appComposeCheckCss {
  position: relative;
  float: left;
  width: 40px;
  height: 60px;
}

.appComposeCheckboxCss {
  width: 16px;
  height: 16px;
  margin: 22px 12px;
  border-radius: 2px;
  border: 1px solid #d2d2d2;
}

.appComposeCheckboxCss:hover {
  border: 1px solid #a0a0a0;
  cursor: pointer;
}

.appComposeNameCss {
  position: relative;
  float: right;
  width: 185px;
  height: 30px;
  padding-left: 5px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  line-height: 30px;
  user-select: none;
  cursor: pointer;
}

.appComposeIdCss {
  position: relative;
  float: right;
  width: 185px;
  height: 30px;
  padding-left: 5px;
  font-size: 12px;
  line-height: 30px;
  user-select: none;
  cursor: pointer;
}

.appComposeFootCss {
  width: 100%;
  height: 76px;
  padding: 10px 0;
}

.appComposeButCss {
  position: relative;
  float: left;
  width: 25%;
  margin: 5px 0;
  border-right: 1px solid #d7d7d7;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
  color: #1694f3;
  cursor: pointer;
}

.appComposeButCss:hover {
  color: #333;
}

.borderNoneCss {
  border: none;
}

.app-material-public {
  position: absolute;
  top: 141px;
  left: 250px;
  height: calc(100% - 50px - 91px);
  width: calc(100% - 250px);
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}

.app-material-nav {
  position: relative;
  width: calc(100% - 250px);
  top: 50px;
  left: 250px;
  min-height: 50px;
  border-bottom: 1px solid #d7d7d7;
  overflow: hidden;
  padding-top: 10px;
  background: #fff;
}

.app-material-nav .name {
  float: left;
  width: 90px;
  height: 50px;
  line-height: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
  padding-left: 10px;
  overflow: hidden;
}

.app-material-nav .url {
  display: block;
  width: auto;
  height: 30px;
  line-height: 30px;
  text-align: center;
  float: left;
  border: 1px solid #d7d7d7;
  margin: 0 10px 10px 0;
  padding: 0 20px;
  color: #333;
  font-size: 12px;
  border-radius: 5px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
}

.material-item {
  position: relative;
  float: left;
  width: 140px;
  margin: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.material-item .img {
  position: relative;
  width: 140px;
  height: 160px;
  line-height: 160px;
  text-align: center;
  border: 1px solid #d7d7d7;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAIAAABLbSncAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDkzNzZFN0RDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDkzNzZFN0VDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEOTM3NkU3QkM4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEOTM3NkU3Q0M4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PktroGEAAAAoSURBVHjaYvz06RMDDPDy8sLZTAw4AOkSjP///4dzPn/+TAs7AAIMAG56COJosoI3AAAAAElFTkSuQmCC");
  background-repeat: repeat;
  overflow: hidden;
}

.material-item .img img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border: none;
  vertical-align: middle;
}

.material-item .btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding: 20px 0;
  background-color: #108cee;
  display: none;
  overflow: hidden;
}

.material-item:hover .btn {
  display: flex;
  flex-flow: column;
}

.material-item .btn a,
.material-item .btn>div {
  flex: 1;
  font-size: 14px;
  line-height: 60px;
  color: rgb(197, 197, 197);
  cursor: pointer;
  text-align: center;
}

.material-item .btn a:hover,
.material-item .btn>div:hover {
  color: #fff;
  background-color: #1686db;
}

.app-material-private {
  position: absolute;
  top: 100px;
  left: 250px;
  height: calc(100% - 100px);
  width: calc(100% - 250px);
  padding: 10px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}

.materialUpload {
  background: #2196f3;
  border-radius: 36px;
  font-size: 14px;
  min-width: 88px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  padding: 0 16px;
  color: #fff;
  margin: 9px 0 9px 20px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.materialUpload:hover {
  background: #3aa6ff;
}

.materialUpload:active {
  background: #3aa6ff;
  box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.4);
}

.selectStreamOrDynaltContentCss {
  margin-top 10px
  position relative
  height 40px
  width 100%
  background #fff
  border 1px solid #3aa6ff
  .selectStreamOrDynaltCss {
    position absolute
    top 0
    left 0
    width 98%
    height 100%
    border none
    outline none
    margin none
    background none
  }
  .rightNavSelectInputCss {
    position absolute
    top 0
    left 0
    width 90%
    height 100%
    border none
  }
}
</style>

<style lang="stylus">
.appComposeCss {
  .tableTitleCss {
    font-size 18px
    font-weight bolder
  }
}
</style>
